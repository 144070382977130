import React from 'react';
import { graphql } from 'gatsby';
import styled from '@emotion/styled';
import { Hero } from '../components/Hero';
import { Template, LogoSquare } from '../components/layout/StyledComponents';
import logo from '../images/vytal-logo.png';
import { GatsbyImage } from 'gatsby-plugin-image';
import { Seo } from '../components/Seo';

interface Props {
    hero: {
        edges: {
            node: {
                childImageSharp: {
                    gatsbyImageData: string;
                };
            };
        }[];
    };
    featuredImage: {
        edges: {
            node: {
                childImageSharp: {
                    gatsbyImageData: string;
                };
            };
        }[];
    };
}

const StyledTemplate = styled(Template)``;

const PatientResources: Page<Props> = ({ data }) => {
    const heroImage = data.hero.edges[0].node.childImageSharp.gatsbyImageData;
    const featuredImage = data.featuredImage.edges[0].node.childImageSharp.gatsbyImageData;
    return (
        <>
            <Seo
                title="Patient Resources – vytalcenter"
                description="Patient resources for Vytal Center Institute of plastic surgery."
            />
            <Hero heroImage={heroImage} title="PATIENT RESOURCES" />
            <StyledTemplate>
                <div className="container">
                    <div className="image-container">
                        <GatsbyImage
                            className="featured-image"
                            alt="girl after breast implants"
                            image={featuredImage}
                        />
                        <LogoSquare className="logo" src={logo} alt="company logo" />
                    </div>
                    <h1>PATIENT RESOURCES</h1>
                    <p>
                        Vytal Surgical Institue is dedicated to provide you with a safe and
                        comfortable surgery experience. To insure successful outcome and a speedy
                        recovery, it is important that you are prepared and are aware of your
                        responsibilities.
                    </p>
                    <h3>Prior to the Date of Surgery</h3>
                    <p>
                        A Vytal Surgical Institue representative will call you to inform you of your
                        scheduled time for surgery, but if, for some unusual reason, you have not
                        been contacted 24 hours prior to your surgery, call us at{' '}
                        <a href="tel:8185785125">818-578-5125</a> to confirm the time you should
                        report to the center for your procedure. Our representative will also inform
                        you of any financial responsibility that would be due. You will need to
                        bring the means for payment with you when you come for your procedure, BUT
                        you should leave all other valuables (like money or jewelry) at home. Vytal
                        Surgical Institue accepts cash, credit cards, and personal checks for
                        pre-payment of charges.
                    </p>
                    <p>
                        Since you will likely receive some level of sedation or anesthesia in
                        connection with your procedure, you will need to arrange for someone to
                        bring you to and take you home from the Center.
                    </p>
                    <p>
                        Please make sure you have followed instructions given to you by your surgeon
                        and the center representative regarding eating, drinking, and taking
                        prescription and non-prescription drugs and dietary supplements.
                    </p>
                    <p>
                        Wear loose, comfortable clothing and shoes that can be easily taken off/put
                        on, and stored.
                    </p>
                    <p>
                        Comfortable, loose clothing is recommended for the day of surgery. Please
                        leave all jewelry and valuables at home. Vytal Surgical Institue is not
                        responsible for any loss of private property.
                    </p>
                    <p>
                        Bring your driver’s license and insurance card with you, and arrange to have
                        a companion drive you home after surgery.
                    </p>
                    <h3>The Recovery Room</h3>
                    <p>
                        You will be taken from the operating or procedure room into your private,
                        monitored bed in our recovery area. A nurse will continuously monitor your
                        recovery from anesthesia or sedation.
                    </p>
                    <p>
                        At the appropriate time, your nurse will offer you a beverage and snack to
                        help in your recovery.
                    </p>
                    <p>
                        When you are ready to be discharged, your companion/family member will be
                        asked to come in, if they are not already there, and your physician will
                        visit you in your bed area to report on the procedure and give you
                        post-operative instructions.
                    </p>
                    <p>
                        A nurse or attendant will accompany you to the exit and see that you are
                        safely in your vehicle for your trip home. If you have received sedation or
                        anesthesia you will not be released without an escort and you will be
                        strongly advised not to drive for 12 hours following your procedure. Since
                        you will be legally impaired for 12-24 hours following sedation, this is for
                        your protection with regard to liability and for your safety.
                    </p>
                    <h3>When You Return Home</h3>
                    <p>
                        A nurse from Vytal Surgical Institue will call you within 24-48 hours to
                        check on your healing/recuperation.
                    </p>
                    <p>
                        If you have any difficulty or experience any symptoms of extreme discomfort,
                        you should contact your physician’s office immediately. You can call Vytal
                        Surgical Institue during the hours of 8:30 AM until 4:30 PM, but at other
                        times your physician’s office and emergency number will be the best
                        recourse.
                    </p>
                    <p>
                        You will be given a patient satisfaction survey when you are discharged and
                        will be asked to complete it and return it to our staff. We will appreciate
                        your candid feedback so we can continually understand how to improve our
                        service.
                    </p>
                    <h3>HIPAA</h3>
                    <p>
                        Under the health Insurance Portability & accountability act of 1996 (HIPPA),
                        patients have certain rights to privacy regarding protected health
                        information. This information can and will be used to:
                    </p>
                    <p>
                        Conduct plan and direct treatment and follow up among the multiple
                        healthcare providers who may be involved in that treatment directly and
                        indirectly.
                    </p>
                    <p>Obtain payment from third party payers.</p>
                    <p>
                        Conduct normal healthcare operations such as quality assessments and
                        physicians certifications.
                    </p>
                    <p>
                        Each patient is informed of Notice of Privacy Practices containing a more
                        complete description of the uses and disclosures regarding health
                        information and is given an opportunity to review such Notice of Privacy
                        Practices prior to signing this contract. This organization has the right to
                        change its Notice of Privacy Practices from time to time and we welcome our
                        patients to contact the organization at any time to obtain a current copy of
                        the Notice of Privacy practice.
                    </p>
                </div>
            </StyledTemplate>
        </>
    );
};

export const query = graphql`
    query {
        hero: allFile(filter: { relativePath: { eq: "hero/girls-office-side.jpeg" } }) {
            edges {
                node {
                    id
                    childImageSharp {
                        gatsbyImageData(width: 2000, quality: 90, webpOptions: { quality: 90 })
                    }
                }
            }
        }
        featuredImage: allFile(filter: { relativePath: { eq: "patient-beds.jpeg" } }) {
            edges {
                node {
                    id
                    childImageSharp {
                        gatsbyImageData(width: 2000, quality: 90, webpOptions: { quality: 90 })
                    }
                }
            }
        }
    }
`;

export default PatientResources;
